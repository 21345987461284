<template>
  <div>
    <div
      style="height: calc(100vh - 145px); overflow-y: auto; overflow-x: hidden"
    >
      <ListWarehouse v-if="isActiveTab('tab-warehouse')" />
      <ListCellType v-if="isActiveTab('tab-cell-type')" />
      <SyncItemEMarket v-if="isActiveTab('tab-sync-item')" />
      <RunSql v-if="isActiveTab('tab-riro')" />
    </div>
    <v-divider class="mt-2"></v-divider>
    <div
      class="pt-2 pb-4 pb-md-2 white-space-nowrap overflow-x-auto overflow-y-hidden"
    >
      <v-btn
        :color="isActiveTab('tab-warehouse') ? 'primary' : 'default'"
        small
        class="mr-2"
        @click="changeTab('tab-warehouse')"
        >{{ $t("labels.warehouse") }}</v-btn
      >
      <v-btn
        :color="isActiveTab('tab-cell-type') ? 'primary' : 'default'"
        small
        class="mr-2"
        @click="changeTab('tab-cell-type')"
        >{{ $t("labels.cell_type") }}</v-btn
      >
      <v-btn
        :color="isActiveTab('tab-sync-item') ? 'primary' : 'default'"
        small
        class="mr-2"
        @click="changeTab('tab-sync-item')"
        >{{ $t("labels.sync_item_e_market") }}</v-btn
      >
      <v-btn
        v-if="isRiRo"
        :color="isActiveTab('tab-riro') ? 'primary' : 'default'"
        small
        class="mr-2"
        @click="changeTab('tab-riro')"
        >RiRo</v-btn
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "Index",
  components: {
    ListWarehouse: () => import("@/components/warehouse/List.vue"),
    ListCellType: () => import("@/components/warehouse/ListCellType.vue"),
    RunSql: () => import("@/components/warehouse/RunSql.vue"),
    SyncItemEMarket: () => import("@/components/warehouse/SyncItemEMarket.vue"),
  },
  data: () => ({
    isLoading: false,
    tab: "tab-warehouse",
    items: [],
    isRiRo: false,
  }),
  computed: {},
  methods: {
    changeTab(tab) {
      this.tab = tab;
    },
    isActiveTab(tab) {
      return this.tab === tab;
    },
  },
  mounted() {
    const riro = this.getUrlParameter("riro");
    this.isRiRo = !!riro;
    if (this.isRiRo) {
      this.tab = "tab-riro";
    }
  },
};
</script>

<style scoped></style>
